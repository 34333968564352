html {
  --game-spacing: 32px;
  --header-height: 4rem;
  --color-success: #17824d;
  --color-warning: #998000;
  --color-error: #c32222;
  --color-gray-100: #1a1a1a;
  --color-gray-300: #404040;
  --color-gray-500: gray;
  --color-gray-700: #bfbfbf;
  --color-gray-900: #e6e6e6;
  overflow-y: scroll;
}

body {
  font-family: sans-serif;
}

@media (max-height: 600px) {
  body {
    --game-spacing: 8px;
  }
}

button {
  cursor: pointer;
  text-align: left;
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.wrapper {
  flex-direction: column;
  min-height: 100%;
  display: flex;
}

header {
  height: var(--header-height);
  border-bottom: 1px solid var(--color-gray-700);
  color: var(--color-gray-300);
  display: flex;
}

header .side {
  width: var(--header-height);
  place-content: center;
  display: grid;
}

h1 {
  font-size: 2rem;
  line-height: var(--header-height);
  text-align: center;
  flex: 1;
}

@media (max-width: 25rem) {
  h1 {
    font-size: 1.25rem;
  }
}

.game-wrapper {
  gap: var(--game-spacing);
  padding: var(--game-spacing) 32px;
  flex-direction: column;
  flex: 1;
  min-width: 250px;
  max-width: min(500px, 58vh, 100%);
  margin: 0 auto;
  display: flex;
}

.guess-results {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  display: flex;
}

.guess {
  justify-content: center;
  gap: 4px;
  margin-bottom: 4px;
  display: flex;
}

.cell {
  aspect-ratio: 1;
  border: 2px solid var(--color-gray-700);
  border-radius: var(--radius);
  place-content: center;
  width: 20%;
  font-size: 2rem;
  display: grid;
  position: relative;
}

.guess:first-of-type .cell:first-of-type {
  --radius: 4px 0px 0px 0px;
}

.guess:first-of-type .cell:last-of-type {
  --radius: 0px 4px 0px 0px;
}

.guess:last-of-type .cell:last-of-type {
  --radius: 0px 0px 4px 0px;
}

.guess:last-of-type .cell:first-of-type {
  --radius: 0px 0px 0px 4px;
}

.cell.correct {
  background: var(--color-success);
  border-color: var(--color-success);
  color: #fff;
}

.cell.incorrect {
  background: var(--color-gray-300);
  border-color: var(--color-gray-300);
  color: #fff;
}

.cell.misplaced {
  background: var(--color-warning);
  border-color: var(--color-warning);
  color: #fff;
}

.guess-input-wrapper {
  flex-direction: column;
  gap: 8px;
  height: 6.75rem;
  display: flex;
}

.guess-input-wrapper label {
  font-size: 1.25rem;
}

.guess-input-wrapper input {
  border: 2px solid var(--color-gray-300);
  outline-offset: 4px;
  border-radius: 4px;
  width: 100%;
  padding: 8px 16px;
  font-size: 2rem;
  display: block;
}

.banner {
  text-align: center;
  will-change: transform;
  border-radius: 4px 4px 0 0;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 32px;
  animation: .75s cubic-bezier(0, .72, .24, 1.02) slideUp;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.happy.banner {
  background: var(--color-success);
  color: #fff;
}

.sad.banner {
  background: var(--color-error);
  color: #fff;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

[data-reach-dialog-content] {
  border-radius: 8px;
  padding: 24px 32px;
  position: relative;
}

.modal-close-btn {
  cursor: pointer;
  padding: 16px;
  position: absolute;
  top: 0;
  right: 0;
}

.modal-title {
  margin-bottom: .5em;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

.keyboard {
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  flex-wrap: wrap;
  justify-content: center;
  gap: .3rem;
  width: 100%;
  font-size: 1rem;
  display: flex;
}

.keyboard-cell {
  background-color: var(--color-gray-900);
  border-radius: 5px;
  width: 8%;
}

.keyboard-cell:nth-child(20), .keyboard-cell:nth-child(28) {
  text-align: center;
  width: 15%;
}

@media screen and (max-width: 600px) {
  .guess-input-wrapper {
    display: none;
  }

  .cell {
    width: 15%;
    font-size: 1.5rem;
  }

  .game-wrapper {
    margin: 5px auto 50px;
    padding: 0;
  }

  header {
    height: 40px;
  }

  h1 {
    line-height: 40px;
  }

  .keyboard {
    width: 355px;
  }

  .keyboard-cell {
    width: 30px;
    font-size: 1.7rem;
  }
}

.keyboard-cell.correct {
  background: var(--color-success);
  border-color: var(--color-success);
  color: #fff;
}

.keyboard-cell.incorrect {
  background: var(--color-gray-300);
  border-color: var(--color-gray-300);
  color: #fff;
}

.keyboard-cell.misplaced {
  background: var(--color-warning);
  border-color: var(--color-warning);
  color: #fff;
}

@media (hover: hover) and (pointer: fine) {
  .keyboard-cell:hover {
    filter: brightness(85%);
    cursor: pointer;
  }
}

.keyboard-cell:active {
  background-color: var(--color-gray-300);
}

.restart {
  color: #000;
  font-size: 24px;
}

.box {
  backface-visibility: hidden;
  perspective: 1000px;
  animation: .82s cubic-bezier(.36, .07, .19, .97) both shake;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

/*# sourceMappingURL=index.f4cf0bc6.css.map */
