/*
  Theme and global variables
*/
html {
  overflow-y: scroll;

  --game-spacing: 32px;
  --header-height: 4rem;

  --color-success: hsl(150deg 70% 30%);
  --color-warning: hsl(50deg 100% 30%);
  --color-error: hsl(0deg 70% 45%);
  --color-gray-100: hsl(0deg 0% 10%);
  --color-gray-300: hsl(0deg 0% 25%);
  --color-gray-500: hsl(0deg 0% 50%);
  --color-gray-700: hsl(0deg 0% 75%);
  --color-gray-900: hsl(0deg 0% 90%);
}

/*
  Custom styles
*/
body {
  font-family: sans-serif;
}

@media (max-height: 600px) {
  body {
    --game-spacing: 8px;
  }
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  color: inherit;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

header {
  display: flex;
  height: var(--header-height);
  border-bottom: 1px solid var(--color-gray-700);
  color: var(--color-gray-300);
}

header .side {
  width: var(--header-height);
  display: grid;
  place-content: center;
}

h1 {
  flex: 1;
  font-size: 2rem;
  line-height: var(--header-height);
  text-align: center;
}

@media (max-width: 25rem) {
  h1 {
    font-size: 1.25rem;
  }
}

.game-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--game-spacing);
  padding: var(--game-spacing) 32px;
  margin: 0 auto;
  min-width: 250px;
  max-width: min(500px, 58vh, 100%);
}

.guess-results {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.guess {
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
  justify-content: center;
}

.cell {
  position: relative;
  width: 20%;
  display: grid;
  place-content: center;
  aspect-ratio: 1 / 1;
  border: 2px solid var(--color-gray-700);
  border-radius: var(--radius);
  font-size: 2rem;
}

/*
  Round the corners for the 4 cells at the very corners of
  the game board
*/
.guess:first-of-type .cell:first-of-type {
  --radius: 4px 0px 0px 0px;
}
.guess:first-of-type .cell:last-of-type {
  --radius: 0px 4px 0px 0px;
}
.guess:last-of-type .cell:last-of-type {
  --radius: 0px 0px 4px 0px;
}
.guess:last-of-type .cell:first-of-type {
  --radius: 0px 0px 0px 4px;
}

.cell.correct {
  background: var(--color-success);
  border-color: var(--color-success);
  color: white;
}
.cell.incorrect {
  background: var(--color-gray-300);
  border-color: var(--color-gray-300);
  color: white;
}
.cell.misplaced {
  background: var(--color-warning);
  border-color: var(--color-warning);
  color: white;
}

.guess-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 6.75rem;
}

.guess-input-wrapper label {
  font-size: 1.25rem;
}

.guess-input-wrapper input {
  display: block;
  width: 100%;
  font-size: 2rem;
  border: 2px solid var(--color-gray-300);
  border-radius: 4px;
  padding: 8px 16px;
  outline-offset: 4px;
}

.banner {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 32px;
  text-align: center;
  animation: slideUp 750ms cubic-bezier(0, 0.72, 0.24, 1.02);
  border-radius: 4px 4px 0px 0px;
  will-change: transform;
}

.happy.banner {
  background: var(--color-success);
  color: white;
}
.sad.banner {
  background: var(--color-error);
  color: white;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

/*
  Modal styles

  HACK: I'm doing this in a quick and dirty way.
  Later in the course, we'll see my preferred way of building
  modals in React.
*/
[data-reach-dialog-content] {
  position: relative;
  border-radius: 8px;
  padding: 24px 32px;
}
.modal-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
  cursor: pointer;
}
.modal-title {
  margin-bottom: 0.5em;
}

/*
  Keyframe animations
*/
@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

/*
  Keyboard
*/

.keyboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  gap: 0.3rem;
  font-size: 1rem;
  width: 100%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.keyboard-cell {
  width: 8%;
  border-radius: 5px;
  background-color: var(--color-gray-900);
}

.keyboard-cell:nth-child(20),
.keyboard-cell:nth-child(28) {
  text-align: center;
  width: 15%;
}

@media screen and (max-width: 600px) {
  .guess-input-wrapper {
    display: none;
  }

  .cell {
    width: 15%;
    font-size: 1.5rem;
  }

  .game-wrapper {
    padding: 0;
    margin: 5px auto;
    margin-bottom: 50px;
  }

  header {
    height: 40px;
  }

  h1 {
    line-height: 40px;
  }

  .keyboard {
    width: 355px;
  }

  .keyboard-cell {
    width: 30px;
    font-size: 1.7rem;
  }
}

.keyboard-cell.correct {
  background: var(--color-success);
  border-color: var(--color-success);
  color: white;
}
.keyboard-cell.incorrect {
  background: var(--color-gray-300);
  border-color: var(--color-gray-300);
  color: white;
}
.keyboard-cell.misplaced {
  background: var(--color-warning);
  border-color: var(--color-warning);
  color: white;
}

@media (hover: hover) and (pointer: fine) {
  .keyboard-cell:hover {
    filter: brightness(85%);
    cursor: pointer;
  }
}

.keyboard-cell:active {
  background-color: var(--color-gray-300);
}

.restart {
  color: black;
  font-size: 24px;
}

/*
  Shake animations
  https://css-tricks.com/snippets/css/shake-css-keyframe-animation/
*/

.box {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
